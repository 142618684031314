import "normalize.css/normalize.css";
import { createGlobalStyle } from "styled-components";
import { navy, blue } from "../constants/Colors";
import { md } from "../constants/MediaQueries";

export const GlobalStyles = createGlobalStyle`
    body {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 16px;
        line-height: 1.5;
        color: ${navy};
    }

    a {
        color: ${blue};
        &:hover {
            text-decoration: none;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 32px 0 0;

        &:first-child {
            margin-top: 0;
        }

        & + p {
            margin-top: 8px;
        }
    }

    p {
        margin: 24px 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        & + h1,
        & + h2,
        & + h3,
        & + h4,
        & + h5,
        & + h6 {
            margin-top: 8px;
        }
    }

    h1 {
        font-size: 32px;
    }
    h2 {
        font-size: 24px;
    }
    h3 {
        font-size: 22px;
    }
    h4 {
        font-size: 20px;
    }
    h5 {
        font-size: 18px;
    }
    h6 {
        font-size: 16px;
    }

    p {
        opacity: .8;
    }

    ${md} {
        h1 {
            font-size: 36px;
        }
        h2 {
            font-size: 28px;
        }
        h3 {
            font-size: 22px;
        }
        h4 {
            font-size: 20px;
        }
        h5 {
            font-size: 18px;
        }
        h6 {
            font-size: 16px;
        }
    }

    .page-content {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }
`;

export default GlobalStyles;
