import styled from "styled-components";
import remark from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";
import React from "react";

export const Markdown = ({
    input,
    ...props
}: { input: string } & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
>) => {
    const content = remark()
        .use(recommended)
        .use(remarkHtml)
        .processSync(input)
        .toString()
        .replace(/\\\s/g, "<br>");
    return <div {...props} dangerouslySetInnerHTML={{ __html: content }}></div>;
};

export const Container = styled.div`
    display: block;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 30px;

    .embed-responsive {
        position: relative;
        padding-bottom: 56.25%;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    p {
        width: auto !important;
    }

    img {
        max-width: 100%;
    }
`;
