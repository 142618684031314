import React from "react";
import styled from "styled-components";
import { Container } from "../global";
import { navy } from "../../constants/Colors";

const Outer = styled.div`
    padding: 24px 0;
    background-color: ${navy};
    text-align: center;
    color: white;
    margin-top: auto;

    p {
        font-size: 14px;
        font-style: italic;
    }
`;

const Footer = () => {
    return (
        <Outer>
            <Container>
                <p>&copy; {new Date().getFullYear()} Highlife Church</p>
            </Container>
        </Outer>
    );
};

export default Footer;
