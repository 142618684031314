import React, { Fragment } from "react";
import GlobalStyles from "./GlobalStyles";
import SEO from "./seo";
import Footer from "./Footer";

interface Props {
    children: React.ReactNode;
}

const Layout = ({ children }: Props) => {
    return (
        <Fragment>
            <SEO />
            <GlobalStyles />
            <div className="page-content">
                <div className="page-content__main">
                    {/* <AlertBar /> */}
                    {children}
                </div>
                <Footer />
            </div>
        </Fragment>
    );
};

export default Layout;
